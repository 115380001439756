import React, { useContext } from "react";
import Recipe from "./Recipe";
import { RecipeContext } from "./App";

export default function RecipeList({ recipes }) {
  const { handleRecipeAdd, handleRecipeRestore } = useContext(RecipeContext);

  return (
    <div className="recipe-list">
      <div>
        {recipes.map((recipe) => {
          return <Recipe key={recipe.id} {...recipe} />;
        })}
      </div>
      <div className="recipe-list__add-recipe-btn-container">
        <button className="btn btn--primary mr-1" onClick={handleRecipeAdd}>
          Add Recipe
        </button>
        {/* <button className="btn btn--danger" onClick={handleRecipeRestore}>
          Restore Recipes
        </button> */}
      </div>
    </div>
  );
}
